var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pa-0 mt-3", attrs: { flat: "" } },
    [
      _c(
        "v-row",
        { staticClass: "mt-3 mb-2 mx-0" },
        [
          _c(
            "v-col",
            { staticClass: "pa-0 pl-1 pt-1", attrs: { cols: "12" } },
            [
              _c("v-autocomplete", {
                attrs: {
                  outlined: "",
                  dense: "",
                  label: _vm.$t("message.projects.project"),
                  items: _vm.listOfProjects,
                  "hide-details": "",
                  "item-text": "projectName",
                  "item-value": "id",
                },
                on: {
                  change: function ($event) {
                    return _vm.getModuleReport()
                  },
                },
                model: {
                  value: _vm.projectId,
                  callback: function ($$v) {
                    _vm.projectId = $$v
                  },
                  expression: "projectId",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.chartLoading
        ? _c("v-skeleton-loader", {
            staticClass: "mx-auto",
            attrs: {
              type: "table-heading, list-item-two-line,  list-item-two-line,  list-item-two-line, list-item-two-line",
            },
          })
        : [
            _c("highcharts", {
              ref: "barChart",
              attrs: { options: _vm.chartOptions },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }